import React, {useEffect, useState} from 'react'
import './buttons.css'
import Input from '../input/Input';
import {useBetween} from "use-between";
import Upload from '../upload/Upload';
import {feelings} from '../data';


const Buttons = () => { // setting the init state
    const [name, setName] = useState("");
    const [hidden, setHidden] = useState(false);

    // updating the state with a hook
    useEffect(() => {
        setName(localStorage.getItem('name'))
    })

    const [feels, setFeels] = useState("");
    const [feelImg, setfeelImg] = useState("");
    const [feelsText, setfeelsText] = useState("");

    const finding = feelings.find(obj => {
        return obj.feeling === "MELLOW";
    });

    const finding1 = feelings.find(obj => {
        return obj.feeling === "HAPPY";
    });

    const finding2 = feelings.find(obj => {
        return obj.feeling === "EXCITED";
    });

    const finding3 = feelings.find(obj => {
        return obj.feeling === "BORED";
    });

    const finding4 = feelings.find(obj => {
        return obj.feeling === "SAD";
    });

    const finding5 = feelings.find(obj => {
        return obj.feeling === "ANNOYED";
    });

    const finding6 = feelings.find(obj => {
        return obj.feeling === "ANGRY";
    });

    const finding7 = feelings.find(obj => {
        return obj.feeling === "SHUTDOWN";
    });

    // prevents missing click when adding to state
    useEffect(() => { // console.log(feels,feelImg,feelsText);
    }, [feels]);

    useEffect(() => { // console.log(feels,feelImg,feelsText);
    }, [feelImg]);

    useEffect(() => {
        // use effect sets state on first click,
        // console.log(feels,feelImg,feelsText);
    }, [feelsText]);


    const buttonClickHandler = (e) => {

        setHidden(true);


        const getRandomText = () => {
            return finding.feelingText[Math.floor(Math.random() * finding.feelingText.length)];
        };
        const randomText = getRandomText();
        setfeelsText(randomText);


        const getRandomText1 = () => {
            return finding1.feelingText[Math.floor(Math.random() * finding1.feelingText.length)];
        };
        const randomText1 = getRandomText1();
        setfeelsText(randomText1);

        const getRandomText2 = () => {
            return finding2.feelingText[Math.floor(Math.random() * finding2.feelingText.length)];
        };
        const randomText2 = getRandomText2();
        setfeelsText(randomText2);

        const getRandomText3 = () => {
            return finding3.feelingText[Math.floor(Math.random() * finding3.feelingText.length)];
        };
        const randomText3 = getRandomText3();
        setfeelsText(randomText3);

        const getRandomText4 = () => {
            return finding4.feelingText[Math.floor(Math.random() * finding4.feelingText.length)];
        };
        const randomText4 = getRandomText4();
        setfeelsText(randomText4);

        const getRandomText5 = () => {
            return finding5.feelingText[Math.floor(Math.random() * finding5.feelingText.length)];
        };
        const randomText5 = getRandomText5();
        setfeelsText(randomText5);

        const getRandomText6 = () => {
            return finding6.feelingText[Math.floor(Math.random() * finding6.feelingText.length)];
        };
        const randomText6 = getRandomText6();
        setfeelsText(randomText6);

        const getRandomText7 = () => {
            return finding7.feelingText[Math.floor(Math.random() * finding7.feelingText.length)];
        };
        const randomText7 = getRandomText7();
        setfeelsText(randomText7);


        if (e.target.innerHTML === "MELLOW") { // mellow
            setFeels(finding.feeling);
            localStorage.setItem('feels', finding.feeling);
            console.log(localStorage.getItem('feels', finding.feeling));

            setfeelImg(finding.src);
            localStorage.setItem('feelImg', finding.src);
            console.log(localStorage.getItem('feelImg', finding.src));

            // setfeelsText(finding.feelingText);

            localStorage.setItem('feelsText', randomText);
            console.log(localStorage.getItem('feelsText', randomText));
            return [finding.feeling, randomText, finding.src];

        } else if (e.target.innerHTML === "HAPPY") { // Happy
            setFeels(finding1.feeling);
            localStorage.setItem('feels', finding1.feeling);
            console.log(localStorage.getItem('feels', finding1.feeling));

            setfeelImg(finding1.src);
            localStorage.setItem('feelImg', finding1.src);
            console.log(localStorage.getItem('feelImg', finding1.src));

            // setfeelsText(finding1.feelingText);
            localStorage.setItem('feelsText', randomText1);
            console.log(localStorage.getItem('feelsText', randomText1));
            return [finding1.feeling, randomText1, finding1.src];
        } else if (e.target.innerHTML === "EXCITED") {
            setFeels(finding2.feeling);
            localStorage.setItem('feels', finding2.feeling);
            console.log(localStorage.getItem('feels', finding2.feeling));

            setfeelImg(finding2.src);
            localStorage.setItem('feelImg', finding2.src);
            console.log(localStorage.getItem('feelImg', finding2.src));

            // setfeelsText(finding2.feelingText);
            localStorage.setItem('feelsText', randomText2);
            console.log(localStorage.getItem('feelsText', randomText2));
            return [finding2.feeling, finding2.feelingText, finding2.src];
        } else if (e.target.innerHTML === "BORED") {
            setFeels(finding3.feeling);
            localStorage.setItem('feels', finding3.feeling);
            console.log(localStorage.getItem('feels', finding3.feeling));

            setfeelImg(finding3.src);
            localStorage.setItem('feelImg', finding3.src);
            console.log(localStorage.getItem('feelImg', finding3.src));

            // setfeelsText(finding3.feelingText);
            localStorage.setItem('feelsText', randomText3);
            console.log(localStorage.getItem('feelsText', randomText3));
            return [finding3.feeling, randomText3, finding3.src];
        } else if (e.target.innerHTML === "SAD") {
            setFeels(finding4.feeling);
            localStorage.setItem('feels', finding4.feeling);
            console.log(localStorage.getItem('feels', finding4.feeling));

            setfeelImg(finding4.src);
            localStorage.setItem('feelImg', finding4.src);
            console.log(localStorage.getItem('feelImg', finding4.src));

            // setfeelsText(finding4.feelingText);
            localStorage.setItem('feelsText', randomText4);
            console.log(localStorage.getItem('feelsText', randomText4));
            return [finding4.feeling, randomText4, finding4.src];
        } else if (e.target.innerHTML === "ANNOYED") {
            setFeels(finding5.feeling);
            localStorage.setItem('feels', finding5.feeling);
            console.log(localStorage.getItem('feels', finding5.feeling));

            setfeelImg(finding5.src);
            localStorage.setItem('feelImg', finding5.src);
            console.log(localStorage.getItem('feelImg', finding5.src));

            // setfeelsText(finding5.feelingText);
            localStorage.setItem('feelsText', randomText5);
            console.log(localStorage.getItem('feelsText', randomText5));
            return [finding5.feeling, randomText5, finding5.src];

        } else if (e.target.innerHTML === "ANGRY") {
            setFeels(finding6.feeling);
            localStorage.setItem('feels', finding6.feeling);
            console.log(localStorage.getItem('feels', finding6.feeling));

            setfeelImg(finding6.src);
            localStorage.setItem('feelImg', finding6.src);
            console.log(localStorage.getItem('feelImg', finding6.src));

            // setfeelsText(finding6.feelingText);
            localStorage.setItem('feelsText', randomText6);
            return [finding6.feeling, randomText6, finding6.src];

        } else if (e.target.innerHTML === "SHUTDOWN") {
            setFeels(finding7.feeling);
            localStorage.setItem('feels', finding7.feeling);
            console.log(localStorage.getItem('feels', finding7.feeling));

            setfeelImg(finding7.src);
            localStorage.setItem('feelImg', finding7.src);
            console.log(localStorage.getItem('feelImg', finding7.src));

            // setfeelsText(finding7.feelingText);
            localStorage.setItem('feelsText',randomText7);
            console.log(localStorage.getItem('feelsText', randomText7));
            return [finding7.feeling, randomText7, finding7.src];
        } else {
            return console.log("end")
        }

    };

    return (
        <div> {
            !hidden ? <div className='center rf_button-container fadeIn1'>
                {/* {
            finding1 && (
                <img alt=""
                    src={
                        finding1.src
                    }
                    width="100px"/>
            )
        }
            {
            finding && (
                <p>{
                    finding.feelingText
                }</p>
            )
        } */}
                <div>
                    <h1>{name}</h1>
                    <span>ARE YOU FEELING...</span>
                </div>
                <button onClick={buttonClickHandler}
                    className='mellow btns'>MELLOW</button>
                <button onClick={buttonClickHandler}
                    className='happy btns'>HAPPY</button>
                <button onClick={buttonClickHandler}
                    className='excited btns'>EXCITED</button>
                <button onClick={buttonClickHandler}
                    className='bored btns'>BORED</button>
                <button onClick={buttonClickHandler}
                    className='sad btns'>SAD</button>
                <button onClick={buttonClickHandler}
                    className='annoyed btns'>ANNOYED</button>
                <button onClick={buttonClickHandler}
                    className='angry btns'>ANGRY</button>
                <button onClick={buttonClickHandler}
                    className='shutdown btns'>SHUTDOWN</button>
            </div> : null
        }

            {
            hidden ? <div><Upload/></div> : null
        } </div>


    )
}

export default Buttons;
