import React from 'react';
import './intro.css';
import Heart from '../../assets/rf_heart_icon.svg';

const Intro = () => {
  return (
      <>
        <div className="introContainer">
            <img className="fadeIn" style={{width: 200}} src={ Heart } alt='Icon'/>
            <h1 className="fadeIn feelingtText" >LET'S SEE HOW YOU'RE FEELING TODAY</h1>

        </div>
        {/* <div>
            <h1 style={{maxWidth: 290}}>LETS'S SEE HOW YOU'RE FEELING TODAY</h1>
        </div> */}
      </>
  )
}

export default Intro